export const AutomationRoutes = [
  {
    path: "/u/automation/auto_reply",
    name: "Autoreply",
    component: () => import("@/views/Dashboard/Automation/Autoreply.vue"),
  },
  {
    path: "/u/automation/auto_reply/new",
    name: "NewIntent",
    component: () => import("@/views/Dashboard/Automation/NewIntent.vue"),
  },
  {
    path: "/u/automation/auto_reply/edit/:id",
    name: "EditIntent",
    component: () => import("@/views/Dashboard/Automation/EditIntent.vue"),
  },
  {
    path: "/u/automation/survey",
    name: "Survey",
    component: () => import("@/views/Dashboard/Automation/Survey.vue"),
  },
  {
    path: "/u/automation/survey_questions/:id",
    name: "SurveyQuestions",
    component: () =>
      import("@/views/Dashboard/Automation/Questions/SurveyQuestions.vue"),
  },
  {
    path: "/u/automation/survey_answers/:id",
    name: "SurveyAnswers",
    component: () =>
      import("@/views/Dashboard/Automation/Answers/SurveyAnswers.vue"),
  },
  {
    path: "/u/automation/survey_questions/:id/new",
    name: "NewQuestion",
    component: () =>
      import("@/views/Dashboard/Automation/Questions/NewQuestion.vue"),
  },
  {
    path: "/u/automation/survey_questions/edit/:question_id",
    name: "EditQuestion",
    component: () =>
      import("@/views/Dashboard/Automation/Questions/EditQuestion.vue"),
  },
];
