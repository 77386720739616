export const AccountRoutes = [
  {
    path: "/u/account/profile",
    name: "Profile",
    component: () => import("@/views/Dashboard/Account/Profile"),
  },
  {
    path: "/u/account/profile/edit",
    name: "ProfileEdit",
    component: () => import("@/views/Dashboard/Account/ProfileEdit"),
  },
  {
    path: "/u/account/password",
    name: "AccountPassword",
    component: () => import("@/views/Dashboard/Account/AccountPassword"),
  },
  {
    path: "/u/account/developer_area",
    name: "DeveloperArea",
    component: () => import("@/views/Dashboard/Account/DeveloperArea"),
  },
];
