<template>
  <div>
    <router-link
      to="/u/messages/send"
      class="wb-button wb-button--small wb-button--primary"
    >
      Send Message
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SendMessage",
};
</script>

<style></style>
