import { BotRoutes } from "./BotRoutes";
import { MessageRoutes } from "./MessageRoutes";
import { ContactRoutes } from "./ContactRoutes";
import { BroadcastRoutes } from "./BroadcastRoutes";
import { AutomationRoutes } from "./AutomationRoutes";
import { AccountRoutes } from "./AccountRoutes";
export const DashboardRoutes = {
  path: "/u/dashboard",
  name: "DashboardLayout",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/Dashboard/Index.vue"),
  children: [
    ...BotRoutes,
    ...MessageRoutes,
    ...ContactRoutes,
    ...BroadcastRoutes,
    ...AutomationRoutes,
    ...AccountRoutes,
  ],
};
