<template>
  <div class="wb-card">
    <EmptyState
      title="No Bots"
      description="Create a new bot to get started"
      :icon="require('@/assets/icons/bots_scan.svg')"
    />
    <div class="d-flex justify-content-center w-100">
      <CreateNewBot />
    </div>
  </div>
</template>

<script>
import CreateNewBot from "@/components/Global/CreateNewBot.vue";
export default {
  name: "NoBotsAvailable",
  components: {
    CreateNewBot,
  },
};
</script>

<style></style>
