<template>
  <modal-wrapper
    :hasClose="true"
    @closeClicked="closeModal"
    v-if="showModal"
    modalTitle="Swicth Bots"
  >
    <div v-if="isLoading" class="d-flex justify-content-center">
      <Spinner
        variant="layout"
        message="Fetching Bots... Hang on."
        :hasMessage="true"
      />
    </div>
    <div v-if="!isLoading && !errorOccurred">
      <div
        class="input-group wb-input-group wb-input-group--rounded wb-input-group--small w-100"
      >
        <input
          type="text"
          class="form-control wb-input"
          placeholder="Search Bots..."
          v-model="searchQuery"
        />
        <span class="input-group-text" id="basic-addon1">
          <i class="bi bi-search"></i>
        </span>
      </div>
      <div v-if="filteredBots.length > 0" class="wb-list my-3">
        <div v-for="bot in filteredBots" :key="bot._id" @click="setBot(bot)">
          <div
            class="wb-list__item d-flex align-items-center justify-content-between"
            :class="{
              'wb-list__item--active':
                activeBotInstance && activeBotInstance._id === bot._id,
            }"
          >
            <div class="d-flex align-items-center">
              <div class="text__body--small text--dark text--regular">
                {{ bot.settings.bot_name }} ({{
                  bot.settings.bot_number || "NOT SET"
                }})
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2">
        <CreateNewBot :buttonFull="true" />
      </div>
    </div>
    <div class="text-center" v-if="errorOccurred">
      <ErrorOccurred
        message=" An error occurred while fetching bots."
        description="There was an error while getting your bots"
        @retry="getBots(true)"
      />
    </div>
    <div
      class="text-center"
      v-if="!isLoading && filteredBots.length == 0 && !errorOccurred"
    >
      <EmptyState
        title="No bots available "
        description="Bots you create will appear here"
        :icon="require('@/assets/icons/bots_scan.svg')"
      />
    </div>
  </modal-wrapper>
</template>

<script>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { eventBus } from "@/utils/eventBus";
import CreateNewBot from "@/components/Global/CreateNewBot";
export default {
  name: "BotsListModal",
  components: {
    CreateNewBot,
  },
  setup() {
    const bots = ref([]);
    const showModal = ref(false);
    const errorOccurred = ref(false);
    const searchQuery = ref("");
    const isLoading = ref(false);
    const activeBotInstance = ref(null);
    const store = useStore();
    activeBotInstance.value = store.getters.activeBotInstance;

    watch([() => store.getters.activeBotInstance], ([newActiveBotInstance]) => {
      activeBotInstance.value = newActiveBotInstance;
    });
    const openModal = () => {
      if (showModal.value) {
        return;
      }
      showModal.value = true;
      getBots();
    };
    const closeModal = () => {
      showModal.value = false;
      searchQuery.value = "";
    };
    const setBot = async (bot) => {
      await store.commit("setInstance", bot);
      closeModal();
    };
    const getBots = async (getOnly = true) => {
      isLoading.value = true;
      try {
        errorOccurred.value = false;
        const { data } = await store.dispatch("getAllBots", getOnly);
        bots.value = data.data;
      } catch (error) {
        console.log(error);
        errorOccurred.value = true;
      } finally {
        isLoading.value = false;
      }
    };
    const filteredBots = computed(() => {
      const query = (searchQuery.value ?? "").toLowerCase().trim();
      if (query) {
        return bots.value.filter((bot) => {
          const botName = bot.settings.bot_name?.toLowerCase() ?? "";
          const botNumber = bot.settings.bot_number?.toLowerCase() ?? "";
          return botName.includes(query) || botNumber.includes(query);
        });
      } else {
        return bots.value;
      }
    });
    onMounted(() => {
      eventBus.on("eventSwitchBots", openModal);
    });
    return {
      getBots,
      showModal,
      openModal,
      closeModal,
      searchQuery,
      isLoading,
      filteredBots,
      activeBotInstance,
      setBot,
      errorOccurred,
    };
  },
};
</script>

<style></style>
