export const BotRoutes = [
  {
    path: "/u/dashboard",
    name: "Overview",
    component: () => import("@/views/Dashboard/Overview/Index.vue"),
  },
  {
    path: "/u/dashboard/bot/active",
    name: "ActiveBot",
    component: () => import("@/views/Dashboard/Overview/ActiveBot.vue"),
  },
  {
    path: "/u/dashboard/bot/connect",
    name: "BotConnection",
    component: () => import("@/views/Dashboard/Bots/BotConnection.vue"),
  },
  {
    path: "/u/dashboard/bot/settings",
    name: "BotSettings",
    component: () => import("@/views/Dashboard/Bots/BotSettings.vue"),
  },
];
