import "bootstrap/dist/js/bootstrap.js";
import { numeric, imgLoaded, maxlength } from "@/directives";
import Toast from "vue-toastification";
import InlineError from "@/components/Global/InlineError";
import Spinner from "@/components/Global/Spinner";
import ModalWrapper from "@/components/Global/ModalWrapper";
import EmptyState from "@/components/Global/EmptyState";
import ErrorOccurred from "@/components/Global/ErrorOccurred.vue";
import CreateAttachment from "@/components/Global/CreateAttachment.vue";
import LineLoader from "@/components/Global/LineLoader.vue";
import FixedMessage from "@/components/Global/FixedMessage.vue";
import LoadMoreButton from "@/components/Global/LoadMoreButton.vue";
import SendMessage from "@/components/Dashboard/SendMessage.vue";
import NoBotsAvailable from "@/components/Dashboard/NoBotsAvailable.vue";
import BotListDropdown from "@/components/Dashboard/Bots/BotListDropdown";
import Import from "@/components/Dashboard/Utils/Import";
import Export from "@/components/Dashboard/Utils/Export";
import VueApexCharts from "vue3-apexcharts";
import { createHead } from "unhead";
const head = createHead();
export default function useImports(app) {
  app.use(VueApexCharts);
  app.directive("numeric", numeric);
  app.directive("img-loaded", imgLoaded);
  app.directive("maxlength", maxlength);
  app.use(head);
  app.use(Toast, {
    timeout: 8000,
    pauseOnFocusLoss: false,
    hideProgressBar: true,
    position: "top-center",
    transition: "Vue-Toastification__fade",
  });

  app.component("InlineError", InlineError);
  app.component("Spinner", Spinner);
  app.component("ModalWrapper", ModalWrapper);
  app.component("EmptyState", EmptyState);
  app.component("ErrorOccurred", ErrorOccurred);
  app.component("SendMessage", SendMessage);
  app.component("NoBotsAvailable", NoBotsAvailable);
  app.component("BotListDropdown", BotListDropdown);
  app.component("CreateAttachment", CreateAttachment);
  app.component("Import", Import);
  app.component("Export", Export);
  app.component("LineLoader", LineLoader);
  app.component("FixedMessage", FixedMessage);
  app.component("LoadMoreButton", LoadMoreButton);
}
